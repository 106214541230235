import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import Header from "../components/Sections/Header";
import Pricing from "../components/Sections/Pricing";
import Services from "../components/Sections/Services";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <div style={{textAlign: "center", marginBottom: "5em"}}>
      <div className="lightBg" style={{ padding: "2em 0" }}>
      <h5 className="font30 extraBold">App is coming soon</h5>
      <p className="font13">
        We are working hard to bring you the best experience. Stay tuned for updates.
      </p>
      </div>
    </div>
      <Services />
      {/*<Projects />
      <Blog />*/}
      <Pricing />
      <Contact />
      <Footer />
    </>
  );
}


